/*
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-08-21 14:47:06
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-21 14:54:27
 * @FilePath: /aurora-carton-web/src/views/statisticalReport/downList/config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const cloums = [
  {
    title:"任务类型",
    fieldKey:"inventoryNo",
    isShow:true,
    className:'table_text2'
  },
  {
    title:"文件名称",
    fieldKey:"inventoryNo",
    isShow:true,
    className:'table_text'
  },
  {
    title:"状态",
    fieldKey:"statusDesc",
    isShow:true,
    className:'table_text'
  },
  {
    title:"操作人",
    fieldKey:"inventoryNo",
    isShow:true,
    className:'table_text'
  },
  {
    title:"创建时间",
    fieldKey:"inventoryNo",
    isShow:true,
    className:'table_text'
  },
  {
    title:"有效时间",
    fieldKey:"inventoryNo",
    isShow:true,
    className:'table_text'
  },
]