<!--
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-08-21 14:23:06
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-21 16:21:47
 * @FilePath: /aurora-carton-web/src/views/statisticalReport/downList/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="down-list">
    <div class="content">
      <div class="queryBoxOne">
        <div class="btnBox"></div>
        <div class="queryItem">
          <el-date-picker
            v-model="formData.time"
            type="daterange"
            class="w280"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getList"
            clearable
          />
          <el-select
            placeholder="请选择状态"
            class="w200"
            v-model="formData.orderStatus"
            @change="getList"
          >
            <el-option label="全部状态" :value="0" />
            <el-option label="已撤销" :value="11" />
            <el-option label="已拒绝" :value="12" />
            <el-option label="进行中" :value="13" />
            <el-option label="待入库" :value="14" />
          </el-select>
          <el-select
            placeholder="请选择操作人"
            class="w200"
            v-model="formData.userName"
            @change="getList"
          >
            <el-option label="王鑫列" :value="0" />
            <el-option label="郑红船" :value="11" />
            <el-option label="张米家" :value="12" />
          </el-select>
          <div class="queryBtn">
            <el-button type="primary" @click="getList" class="square"
              ><el-icon><Search /></el-icon
            ></el-button>
          </div>
        </div>
      </div>
      <div class="tabList">
        <zh-table :data="tableData" @row-click="checkGoods">
          <el-table-column
            type="index"
            width="100"
            label="序号"
            align="center"
            fixed
          />
          <template v-for="item in cloums" :key="item.fieldKey">
            <el-table-column
              align="center"
              :prop="item.fieldKey"
              :label="item.title"
              :width="item.width"
              :class-name="item.className"
              show-overflow-tooltip
            >
              <template v-if="item.fieldKey == 'statusDesc'" #default="scope">
                <div class="zh-down-text">
                  <img
                    src="@assets/img/downList/ok.png"
                    v-if="scope.$index == 0"
                    alt="位元灵感"
                    class="status-img ok"
                  />
                  <img
                    src="@assets/img/downList/loading.png"
                    v-if="scope.$index == 1"
                    alt="位元灵感"
                    class="status-img loading"
                  />
                  <span class="stauts-text ok" v-if="scope.$index == 0"
                    >已生成</span
                  >
                  <span
                    class="stauts-text loading"
                    v-else-if="scope.$index == 1"
                    >生成中</span
                  >
                  <span class="stauts-text failed" v-else-if="scope.$index == 2"
                    >生成失败</span
                  >
                  <span class="stauts-text time-out" v-else>已失效</span>
                </div>
              </template>
            </el-table-column>
          </template>
          <el-table-column
            width="100"
            label="操作"
            align="center"
          >
          <div class="c_blue cur_p">下载</div>
          </el-table-column>
        </zh-table>
      </div>
      <div class="mainPage">
        <el-pagination
          v-model:current-page="pageInfo.pageNumber"
          layout="total, prev, pager, next , jumper"
          :total="pageInfo.total"
          v-model:page-size="pageInfo.pageSize"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {getCurrentInstance, reactive, ref} from "vue";
import {cloums} from "./config";
import {getDomHeight} from "@/utils/tool/getDomHeight.js";
import {usePagination} from "@utils/tool/page";

const { domHeight } = getDomHeight(".queryBoxOne"); // 获取搜索栏的高度，动态计算table的高度

const {pageInfo} = usePagination()
const { proxy } = getCurrentInstance();
const tableData = ref([]);
const formData = reactive({
  query: "",
  searchList: [],
  time: [],
  orderStatus: 0,
  userName: 0,
});

const handleCurrentChange = (val) => {};

const getList = () => {};

const checkGoods = (e) => {
};
</script>

<style lang="scss" scoped src="./index.scss"></style>
<style lang="scss" scoped>
.tabList {
  margin-top: 20px;
  height: calc(100% - 84px - v-bind(domHeight));

}
</style>
